import React from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import multiClass from '../../helpers/multiClass'
import { InstagramIcon, YoutubeIcon } from '../SocialIcons'

const MobileMenu = props => {
  const { contatctLink, className } = props

  return (
    <nav role="navigation" className={className}>
      <div className={style.menuMobileToggle}>
        <input type="checkbox" />

        <span />
        <span />
        <span />

        <ul className={style.menuMobile} id="menu">
          <a href={contatctLink}>
            <li className={style.contactMenu}>CONTATO</li>
          </a>
          {/* <a href="/arquitetura-na-pratica">
            <li>ARQUITETURA NA PRÁTICA</li>
          </a> */}
          <div className={style.menuMediaContainer}>
            <a
              className={multiClass(style.socialLink, style.menuInstagramLink)}
              href="https://www.instagram.com/studiom4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.youtube.com/channel/UCeyH-g2_EXMFpGlTM9v6ZJg"
              className={multiClass(style.socialLink, style.menuYoutubeLink)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <YoutubeIcon />
            </a>
          </div>
        </ul>
      </div>
    </nav>
  )
}

MobileMenu.propTypes = {
  contatctLink: PropTypes.string,
  className: PropTypes.string
}

MobileMenu.defaultProps = {
  contatctLink: '',
  className: ''
}

export default MobileMenu
