import React, { Component } from 'react'
import { Link } from 'gatsby'
import style from './style.module.scss'
import logoBw from '../../images/logo-m4-bw.svg'
import { InstagramIcon, YoutubeIcon } from '../SocialIcons'

class Footer extends Component {
  state = {}

  render() {
    return (
      <div className={style.footer}>
        <Link to="/" className={style.logom4}>
          <img
            src={logoBw}
            className={style.logoDesktop}
            alt="Logo do Studio M4 em preto e branco"
          />
        </Link>

        <div className={style.contactContainer}>
          <div className={style.addressContainer}>
            <p>SHIN CA 7, Bloco P, Número 90 - Lago Norte</p>
            <p>CEP 70.503-507 | Brasília, DF</p>
            <a
              href="https://goo.gl/maps/uF18tc3Ufo1FenJy7"
              target="_blank"
              rel="noopener noreferrer"
            >
              ver no mapa
            </a>
          </div>
          <div className={style.phoneContainer}>
            <a href="tel:+556135461446">
              <p>+ 55 61 3546-1446</p>
            </a>
            <a
              href="mailto:contato@studiom4.com.br"
              style={{ fontWeight: 'normal' }}
            >
              <p>contato@studiom4.com.br</p>
            </a>
          </div>
        </div>
        <div className={style.rightContainer}>
          <div className={style.socialLinks}>
            <a
              href="https://www.instagram.com/studiom4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.youtube.com/channel/UCeyH-g2_EXMFpGlTM9v6ZJg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YoutubeIcon />
            </a>
          </div>
          <div className={style.copyright}>
            <p>©2019 StudioM4</p>
            <a
              href="https://www.novatics.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>
                Feito com <strong>&#10084;</strong> por{' '}
                <strong>Novatics</strong>
              </p>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
