import React from 'react'
import PropTypes from 'prop-types'

const TrianglesInstagramFeed = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="208"
    height="209"
    viewBox="0 0 208 209"
    className={className}
  >
    <g fill="none" fillRule="nonzero" opacity=".5">
      <path fill="#EFEFEF" d="M104 209H0l52-52z" />
      <path fill="#B9BFC8" d="M0 104h104l-52 52z" />
      <path fill="#EFEFEF" d="M208 104v104L104 104zM0 104V0l104 104z" />
    </g>
  </svg>
)

const Heart = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="heart"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className="svg-inline--fa fa-heart fa-w-16 fa-3x"
  >
    <path
      fill="currentColor"
      d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
      className=""
    />
  </svg>
)

const Comment = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="comment"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className="svg-inline--fa fa-comment fa-w-16 fa-3x"
  >
    <path
      fill="currentColor"
      d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7S4.8 480 8 480c66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 141.4 0 256-93.1 256-208S397.4 32 256 32z"
      className=""
    />
  </svg>
)

TrianglesInstagramFeed.propTypes = {
  className: PropTypes.string.isRequired
}

export { TrianglesInstagramFeed, Heart, Comment }
