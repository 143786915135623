import React from 'react'

const TriangleProjectOne = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="74"
    height="72"
    viewBox="0 0 74 72"
    className={className}
  >
    <g fill="#12B2B2" fillRule="nonzero">
      <path d="M0 72V0l37 36z" opacity=".15" />
      <path d="M37 72V35l37 37z" opacity=".05" />
    </g>
  </svg>
)

const TriangleProjectTwo = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="142"
    height="143"
    viewBox="0 0 142 143"
    className={className}
  >
    <g fill="#12B2B2" fillRule="nonzero">
      <path d="M71 71H0l35.5-35zM142 143H71l35.5-36z" opacity=".15" />
      <path d="M0 0h71L35.5 36zM71 71h71l-35.5 36z" opacity=".05" />
      <path d="M142 0v71L71 0z" opacity=".15" />
    </g>
  </svg>
)

const TriangleConsultOne = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="141"
    height="144"
    viewBox="0 0 141 144"
    className={className}
  >
    <g fill="#FFBF68" fillRule="nonzero">
      <path d="M70 0h71l-35.5 35z" opacity=".15" />
      <path d="M141 71H70l35.5-36z" opacity=".3" />
      <path d="M0 71V0l70 71zM141 71v73L70 71z" opacity=".15" />
    </g>
  </svg>
)

const TriangleConsultTwo = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="111"
    height="73"
    viewBox="0 0 111 73"
    className={className}
  >
    <g fill="#FFBF68" fillRule="evenodd">
      <path d="M111 73H36l37.5-37z" opacity=".3" />
      <path d="M36 0h75L73.5 36zM36 0v73L0 36.5z" opacity=".15" />
    </g>
  </svg>
)

const TriangleConsultThree = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="71"
    viewBox="0 0 36 71"
    className={className}
  >
    <path
      fill="#FFBF68"
      fillRule="nonzero"
      d="M0 71.064v-71l36 35.5z"
      opacity=".15"
    />
  </svg>
)

const TrianglePublicityOne = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="141"
    height="71"
    viewBox="0 0 141 71"
    className={className}
  >
    <g fill="#7654CC" fillRule="nonzero">
      <path d="M70 0h71L70 71zM0 0v71l35-35.5z" opacity=".1" />
      <path d="M70 71V0L35 35.5z" opacity=".2" />
    </g>
  </svg>
)

const TrianglePublicityTwo = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="146"
    height="145"
    viewBox="0 0 146 145"
    className={className}
  >
    <g fill="#7654CC" fillRule="nonzero">
      <path d="M74 0v72l36-36zM0 145h74l-37-37z" opacity=".1" />
      <path d="M146 72V0l-36 36zM73 72H1l36 36z" opacity=".2" />
      <path d="M146 72H74l72 72z" opacity=".1" />
    </g>
  </svg>
)

export {
  TriangleProjectOne,
  TriangleProjectTwo,
  TriangleConsultOne,
  TriangleConsultTwo,
  TriangleConsultThree,
  TrianglePublicityOne,
  TrianglePublicityTwo
}
