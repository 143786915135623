import React, { Component } from 'react'
import PropTypes from 'prop-types'
import multiClass from '../../helpers/multiClass'

import style from './style.module.scss'
import { TrianglesInstagramFeed, Heart } from './TrianglesInstagramFeed'

import InstagramPhoto01 from '../../images/instagram/01.jpeg'
import InstagramPhoto02 from '../../images/instagram/02.jpeg'
import InstagramPhoto03 from '../../images/instagram/03.jpeg'
import InstagramPhoto04 from '../../images/instagram/04.jpeg'
import InstagramPhoto05 from '../../images/instagram/05.jpeg'
import InstagramPhoto06 from '../../images/instagram/06.jpeg'

class InstagramFeed extends Component {
  state = {}

  render() {
    const { title, mobileTitle, mobileClassName } = this.props

    const instagramData = [
      {
        order: '01',
        image: InstagramPhoto01,
        url: 'https://www.instagram.com/p/CDHHuAPjlOq/?igshid=m1q9xqzyfjzx'
      },
      {
        order: '02',
        image: InstagramPhoto02,
        url: 'https://www.instagram.com/p/CChN_BqDTrG/?igshid=6gx52ok0hfg8'
      },
      {
        order: '03',
        image: InstagramPhoto03,
        url: 'https://www.instagram.com/p/CCeoX_9jNLS/?igshid=1me4hmd13vcu2'
      },
      {
        order: '04',
        image: InstagramPhoto04,
        url: 'https://www.instagram.com/p/CBV_a3cjLqF/?igshid=uy6snuii6da1'
      },
      {
        order: '05',
        image: InstagramPhoto05,
        url: 'https://www.instagram.com/p/CAsgYCSDRKx/?igshid=ddo047qdmzwp'
      },
      {
        order: '06',
        image: InstagramPhoto06,
        url: 'https://www.instagram.com/p/CAq2yqFjRJA/?igshid=1bb1w03gfkng3'
      }
    ]

    return (
      <div className={style.instagramFeed}>
        {title && <h3 className={style.desktopTitle}>{title}</h3>}
        {mobileTitle && <h3 className={style.mobileTitle}>{mobileTitle}</h3>}
        <div className={multiClass(style.instafeed, style[mobileClassName])}>
          {instagramData.map(item => (
            <a
              href={item.url}
              key={item.order}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                style={{
                  backgroundImage: `url(${item.image})`
                }}
              >
                <content>
                  <Heart />
                </content>
              </div>
            </a>
          ))}
          <TrianglesInstagramFeed className={style.trianglesInstagramFeed} />
        </div>
      </div>
    )
  }
}

InstagramFeed.propTypes = {
  title: PropTypes.string,
  mobileTitle: PropTypes.string,
  mobileClassName: PropTypes.string
}

InstagramFeed.defaultProps = {
  title: '',
  mobileTitle: '',
  mobileClassName: ''
}

export default InstagramFeed
