import React, { Component } from 'react'
import style from './style.module.scss'
import {
  TriangleProjectOne,
  TriangleProjectTwo,
  TriangleConsultOne,
  TriangleConsultTwo,
  TriangleConsultThree,
  TrianglePublicityOne,
  TrianglePublicityTwo
} from './TrianglesContactBoxes'
import { RightArrow } from '../RightArrow'

class ContactBoxes extends Component {
  state = {}

  redirectTo = redirectUrl => {
    window.location = redirectUrl
  }

  render() {
    const contacts = [
      {
        number: '01',
        redirectUrl: '/fazer-um-projeto',
        typeContactTitle: 'Tenho interesse em',
        title: 'Fazer um Projeto',
        color: 'rgba(18, 178, 178, 0.2)',
        colorTitle: '#12b2b2',
        triagles: [
          <TriangleProjectOne key="p1" className={style.triangleProjectOne} />,
          <TriangleProjectTwo key="p2" className={style.triangleProjectTwo} />
        ]
      },
      {
        number: '02',
        redirectUrl: '/contato-consultoria',
        typeContactTitle: 'Tenho interesse em',
        title: 'Fazer uma Consultoria',
        color: 'rgba(255, 191, 104, 0.2)',
        colorTitle: '#ffbf68',
        triagles: [
          <TriangleConsultOne key="c1" className={style.triangleConsultOne} />,
          <TriangleConsultTwo key="c2" className={style.triangleConsultTwo} />,
          <TriangleConsultThree
            key="c3"
            className={style.triangleConsultThree}
          />
        ]
      },
      {
        number: '03',
        redirectUrl: '/contato-publicidade',
        typeContactTitle: 'Quero parceria do Studio M4 para',
        title: 'Publicidade',
        color: 'rgba(118, 84, 204, 0.2)',
        colorTitle: '#7654cc',
        triagles: [
          <TrianglePublicityOne
            key="u1"
            className={style.trianglePublicityOne}
          />,
          <TrianglePublicityTwo
            key="u2"
            className={style.trianglePublicityTwo}
          />
        ]
      }
    ]
    return (
      <div className={style.contactBoxes} id="contato">
        {contacts.map(item => (
          <div
            key={item.number}
            className={style.boxContainer}
            style={{ '--box-color': item.color }}
            onClick={() => this.redirectTo(item.redirectUrl)}
          >
            {item.triagles}
            <div className={style.textContainer}>
              <p
                className={style.typeContactTitle}
                style={{ '--color-title': item.colorTitle }}
              >
                {item.typeContactTitle}
              </p>
              <p className={style.title}>
                {item.title}
                <RightArrow className={style.titleArrow} />
              </p>
              <RightArrow className={style.desktopArrow} />
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default ContactBoxes
